import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Section from './Section';

const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-lg);
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ServiceCard = styled.div`
  background-color: white;
  border-radius: var(--radius-md);
  padding: var(--spacing-lg);
  box-shadow: var(--shadow-md);
  transition: transform var(--transition-normal), box-shadow var(--transition-normal);
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: var(--shadow-lg);
  }
`;

const ServiceIcon = styled.div`
  width: 60px;
  height: 60px;
  background-color: var(--primary);
  color: white;
  border-radius: var(--radius-full);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  margin-bottom: var(--spacing-md);
`;

const ServiceTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: var(--spacing-sm);
`;

const ServiceDescription = styled.p`
  color: var(--gray);
  margin-bottom: 0;
`;

const ServicesSection = () => {
  const { t } = useTranslation();

  const services = [
    {
      id: 'service1',
      icon: 'fas fa-paint-brush',
      title: t('services.service1.title'),
      description: t('services.service1.description'),
    },
    {
      id: 'service2',
      icon: 'fas fa-chart-line',
      title: t('services.service2.title'),
      description: t('services.service2.description'),
    },
    {
      id: 'service3',
      icon: 'fas fa-mobile-alt',
      title: t('services.service3.title'),
      description: t('services.service3.description'),
    },
    {
      id: 'service4',
      icon: 'fas fa-search',
      title: t('services.service4.title'),
      description: t('services.service4.description'),
    },
  ];

  return (
    <Section 
      id="services" 
      title={t('services.title')} 
      subtitle={t('services.subtitle')}
      bgColor="var(--light)"
    >
      <ServicesGrid>
        {services.map((service) => (
          <ServiceCard key={service.id}>
            <ServiceIcon>
              <i className={service.icon}></i>
            </ServiceIcon>
            <ServiceTitle>{service.title}</ServiceTitle>
            <ServiceDescription>{service.description}</ServiceDescription>
          </ServiceCard>
        ))}
      </ServicesGrid>
    </Section>
  );
};

export default ServicesSection; 