import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Section from './Section';
import Button from './Button';

const PricingContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-lg);
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const PricingCard = styled.div`
  background-color: white;
  border-radius: var(--radius-md);
  padding: var(--spacing-lg);
  box-shadow: var(--shadow-md);
  text-align: center;
  transition: transform var(--transition-normal), box-shadow var(--transition-normal);
  position: relative;
  overflow: hidden;
  
  ${props => props.popular && `
    transform: scale(1.05);
    box-shadow: var(--shadow-lg);
    border: 2px solid var(--primary);
    
    @media (max-width: 992px) {
      transform: scale(1);
    }
  `}
  
  &:hover {
    transform: translateY(-10px);
    box-shadow: var(--shadow-lg);
  }
`;

const PopularBadge = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--primary);
  color: white;
  padding: 0.5rem 1.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  transform: rotate(45deg) translateX(30%) translateY(-50%);
  width: 200px;
  text-align: center;
`;

const PricingHeader = styled.div`
  margin-bottom: var(--spacing-lg);
  padding-bottom: var(--spacing-md);
  border-bottom: 1px solid var(--gray-light);
`;

const PricingTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: var(--spacing-sm);
`;

const PricingPrice = styled.div`
  font-size: 3rem;
  font-weight: 700;
  color: var(--primary);
  margin-bottom: var(--spacing-sm);
`;

const PricingDescription = styled.p`
  color: var(--gray);
  margin-bottom: 0;
`;

const PricingFeatures = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 var(--spacing-lg);
  text-align: left;
`;

const PricingFeature = styled.li`
  padding: var(--spacing-sm) 0;
  display: flex;
  align-items: center;
  
  &:not(:last-child) {
    border-bottom: 1px solid var(--gray-light);
  }
  
  &::before {
    content: '✓';
    color: var(--success);
    margin-right: var(--spacing-sm);
    font-weight: bold;
  }
`;

const PricingSection = () => {
  const { t } = useTranslation();

  const pricingPlans = [
    {
      id: 'basic',
      title: t('pricing.basic.title'),
      price: t('pricing.basic.price'),
      description: t('pricing.basic.description'),
      features: t('pricing.basic.features', { returnObjects: true }),
      cta: t('pricing.basic.cta'),
      popular: false,
    },
    {
      id: 'standard',
      title: t('pricing.standard.title'),
      price: t('pricing.standard.price'),
      description: t('pricing.standard.description'),
      features: t('pricing.standard.features', { returnObjects: true }),
      cta: t('pricing.standard.cta'),
      popular: true,
    },
    {
      id: 'premium',
      title: t('pricing.premium.title'),
      price: t('pricing.premium.price'),
      description: t('pricing.premium.description'),
      features: t('pricing.premium.features', { returnObjects: true }),
      cta: t('pricing.premium.cta'),
      popular: false,
    },
  ];

  return (
    <Section 
      id="pricing" 
      title={t('pricing.title')} 
      subtitle={t('pricing.subtitle')}
      bgColor="var(--light)"
    >
      <PricingContainer>
        {pricingPlans.map((plan) => (
          <PricingCard key={plan.id} popular={plan.popular}>
            {plan.popular && <PopularBadge>Popular</PopularBadge>}
            <PricingHeader>
              <PricingTitle>{plan.title}</PricingTitle>
              <PricingPrice>{plan.price}</PricingPrice>
              <PricingDescription>{plan.description}</PricingDescription>
            </PricingHeader>
            <PricingFeatures>
              {plan.features.map((feature, index) => (
                <PricingFeature key={index}>{feature}</PricingFeature>
              ))}
            </PricingFeatures>
            <Button 
              variant={plan.popular ? 'primary' : 'outline'} 
              fullWidth
            >
              {plan.cta}
            </Button>
          </PricingCard>
        ))}
      </PricingContainer>
    </Section>
  );
};

export default PricingSection; 