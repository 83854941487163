import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const SwitcherContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LanguageButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.active ? 'var(--primary)' : 'var(--gray)'};
  font-weight: ${props => props.active ? '600' : '400'};
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  font-size: 0.875rem;
  transition: color var(--transition-fast);

  &:hover {
    color: var(--primary);
    background: none;
  }

  &:focus {
    outline: none;
  }
`;

const Divider = styled.span`
  color: var(--gray-light);
  margin: 0 0.25rem;
`;

const LanguageSwitcher = () => {
  const { i18n, t } = useTranslation();
  
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <SwitcherContainer>
      <LanguageButton 
        active={i18n.language === 'vi'} 
        onClick={() => changeLanguage('vi')}
      >
        {t('language.vi')}
      </LanguageButton>
      <Divider>|</Divider>
      <LanguageButton 
        active={i18n.language === 'en'} 
        onClick={() => changeLanguage('en')}
      >
        {t('language.en')}
      </LanguageButton>
    </SwitcherContainer>
  );
};

export default LanguageSwitcher; 