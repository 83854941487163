import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Section from './Section';

const TestimonialsContainer = styled.div`
  position: relative;
  padding: 0 var(--spacing-lg);
  
  @media (max-width: 768px) {
    padding: 0;
  }
`;

const TestimonialSlider = styled.div`
  display: flex;
  overflow: hidden;
  position: relative;
`;

const TestimonialSlide = styled.div`
  min-width: 100%;
  transition: transform 0.5s ease;
  transform: translateX(${props => props.offset}%);
`;

const TestimonialCard = styled.div`
  background-color: white;
  border-radius: var(--radius-md);
  padding: var(--spacing-lg);
  box-shadow: var(--shadow-md);
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
`;

const TestimonialText = styled.p`
  font-size: 1.25rem;
  font-style: italic;
  margin-bottom: var(--spacing-md);
  color: var(--dark);
  position: relative;
  
  &::before, &::after {
    content: '"';
    font-size: 3rem;
    color: var(--primary);
    opacity: 0.2;
    position: absolute;
  }
  
  &::before {
    top: -20px;
    left: -10px;
  }
  
  &::after {
    bottom: -40px;
    right: -10px;
  }
`;

const TestimonialAuthor = styled.h4`
  font-size: 1.25rem;
  margin-bottom: var(--spacing-xs);
`;

const TestimonialPosition = styled.p`
  color: var(--gray);
  font-size: 0.875rem;
  margin-bottom: 0;
`;

const TestimonialAvatar = styled.div`
  width: 80px;
  height: 80px;
  border-radius: var(--radius-full);
  overflow: hidden;
  margin: 0 auto var(--spacing-md);
  border: 3px solid var(--primary);
`;

const TestimonialImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const SliderControls = styled.div`
  display: flex;
  justify-content: center;
  margin-top: var(--spacing-lg);
`;

const SliderDot = styled.button`
  width: 12px;
  height: 12px;
  border-radius: var(--radius-full);
  background-color: ${props => props.active ? 'var(--primary)' : 'var(--gray)'};
  border: none;
  margin: 0 var(--spacing-xs);
  cursor: pointer;
  transition: background-color var(--transition-fast);
  
  &:hover {
    background-color: ${props => props.active ? 'var(--primary-dark)' : 'var(--primary)'};
  }
`;

const SliderArrow = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: var(--radius-full);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--shadow-md);
  cursor: pointer;
  z-index: 1;
  font-size: 1.25rem;
  color: var(--primary);
  
  &:hover {
    background-color: var(--primary);
    color: white;
  }
  
  &.prev {
    left: 0;
  }
  
  &.next {
    right: 0;
  }
  
  @media (max-width: 768px) {
    display: none;
  }
`;

const TestimonialsSection = () => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [autoplay, setAutoplay] = useState(true);

  const testimonials = [
    {
      id: 1,
      text: t('testimonials.testimonial1.text'),
      author: t('testimonials.testimonial1.author'),
      position: t('testimonials.testimonial1.position'),
      avatar: 'https://randomuser.me/api/portraits/men/92.jpg',
    },
    {
      id: 2,
      text: t('testimonials.testimonial2.text'),
      author: t('testimonials.testimonial2.author'),
      position: t('testimonials.testimonial2.position'),
      avatar: 'https://randomuser.me/api/portraits/women/44.jpg',
    },
    {
      id: 3,
      text: t('testimonials.testimonial3.text'),
      author: t('testimonials.testimonial3.author'),
      position: t('testimonials.testimonial3.position'),
      avatar: 'https://randomuser.me/api/portraits/men/67.jpg',
    },
  ];

  // Memoize the autoplay function to prevent unnecessary re-renders
  const handleAutoplay = useCallback(() => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  }, [testimonials.length]);

  useEffect(() => {
    let interval;
    
    if (autoplay) {
      interval = setInterval(handleAutoplay, 5000);
    }
    
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [autoplay, handleAutoplay]);

  const handleDotClick = (index) => {
    setActiveIndex(index);
    setAutoplay(false);
  };

  const handlePrevClick = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
    setAutoplay(false);
  };

  const handleNextClick = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    setAutoplay(false);
  };

  return (
    <Section 
      id="testimonials" 
      title={t('testimonials.title')}
      bgColor="var(--gray-light)"
    >
      <TestimonialsContainer>
        <SliderArrow 
          className="prev" 
          onClick={handlePrevClick}
          aria-label="Previous testimonial"
        >
          <i className="fas fa-chevron-left"></i>
        </SliderArrow>
        
        <TestimonialSlider>
          {testimonials.map((testimonial, index) => (
            <TestimonialSlide 
              key={testimonial.id} 
              offset={-100 * activeIndex}
              aria-hidden={index !== activeIndex}
            >
              <TestimonialCard>
                <TestimonialAvatar>
                  <TestimonialImage src={testimonial.avatar} alt={testimonial.author} />
                </TestimonialAvatar>
                <TestimonialText>{testimonial.text}</TestimonialText>
                <TestimonialAuthor>{testimonial.author}</TestimonialAuthor>
                {/* <TestimonialPosition>{testimonial.position}</TestimonialPosition> */}
              </TestimonialCard>
            </TestimonialSlide>
          ))}
        </TestimonialSlider>
        
        <SliderArrow 
          className="next" 
          onClick={handleNextClick}
          aria-label="Next testimonial"
        >
          <i className="fas fa-chevron-right"></i>
        </SliderArrow>
        
        <SliderControls>
          {testimonials.map((_, index) => (
            <SliderDot 
              key={index} 
              active={index === activeIndex} 
              onClick={() => handleDotClick(index)}
              aria-label={`Go to testimonial ${index + 1}`}
              aria-current={index === activeIndex}
            />
          ))}
        </SliderControls>
      </TestimonialsContainer>
    </Section>
  );
};

export default TestimonialsSection; 