import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
  width: 100%;
  max-width: ${props => props.small ? '960px' : 'var(--container-width)'};
  margin: 0 auto;
  padding: 0 var(--spacing-md);
`;

const Container = ({ children, small = false, className, ...props }) => {
  return (
    <StyledContainer small={small} className={className} {...props}>
      {children}
    </StyledContainer>
  );
};

export default Container; 