import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    /* Colors */
    --primary: #f97316;
    --primary-dark: #000000;
    --secondary: #10b981;
    --secondary-dark: #059669;
    --dark: #1e293b;
    --light: #f8fafc;
    --gray: #64748b;
    --gray-light: #e2e8f0;
    --danger: #ef4444;
    --warning: #f59e0b;
    --success: #10b981;

    /* Typography */
    --font-primary: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    --font-heading: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    
    /* Spacing */
    --spacing-xs: 0.25rem;
    --spacing-sm: 0.5rem;
    --spacing-md: 1rem;
    --spacing-lg: 2rem;
    --spacing-xl: 4rem;
    
    /* Border Radius */
    --radius-sm: 0.25rem;
    --radius-md: 0.5rem;
    --radius-lg: 1rem;
    --radius-full: 9999px;
    
    /* Shadows */
    --shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    
    /* Transitions */
    --transition-fast: 150ms ease;
    --transition-normal: 300ms ease;
    --transition-slow: 500ms ease;
    
    /* Container */
    --container-width: 1200px;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html {
    font-size: 16px;
    scroll-behavior: smooth;
  }

  body {
    font-family: var(--font-primary);
    color: var(--dark);
    background-color: var(--light);
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-heading);
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: var(--spacing-md);
  }

  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 2rem;
  }

  h4 {
    font-size: 1.5rem;
  }

  h5 {
    font-size: 1.25rem;
  }

  h6 {
    font-size: 1rem;
  }

  p {
    margin-bottom: var(--spacing-md);
  }

  a {
    color: var(--primary);
    text-decoration: none;
    transition: color var(--transition-fast);
  }

  a:hover {
    color: var(--primary-dark);
  }

  img {
    max-width: 100%;
    height: auto;
  }

  button, .button {
    cursor: pointer;
    font-family: var(--font-primary);
    font-weight: 600;
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
    border-radius: var(--radius-md);
    border: none;
    background-color: var(--primary);
    color: white;
    transition: all var(--transition-fast);
  }

  button:hover, .button:hover {
    background-color: var(--primary-dark);
  }

  button:disabled, .button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  input, textarea, select {
    font-family: var(--font-primary);
    font-size: 1rem;
    padding: 0.75rem 1rem;
    border: 1px solid var(--gray-light);
    border-radius: var(--radius-md);
    width: 100%;
    transition: border-color var(--transition-fast);
  }

  input:focus, textarea:focus, select:focus {
    outline: none;
    border-color: var(--primary);
  }

  ul, ol {
    margin-bottom: var(--spacing-md);
    padding-left: var(--spacing-lg);
  }

  .container {
    width: 100%;
    max-width: var(--container-width);
    margin: 0 auto;
    padding: 0 var(--spacing-md);
  }

  .section {
    padding: var(--spacing-xl) 0;
  }

  .text-center {
    text-align: center;
  }

  .mb-0 {
    margin-bottom: 0;
  }

  .mb-1 {
    margin-bottom: var(--spacing-xs);
  }

  .mb-2 {
    margin-bottom: var(--spacing-sm);
  }

  .mb-3 {
    margin-bottom: var(--spacing-md);
  }

  .mb-4 {
    margin-bottom: var(--spacing-lg);
  }

  .mb-5 {
    margin-bottom: var(--spacing-xl);
  }

  @media (max-width: 768px) {
    html {
      font-size: 14px;
    }
    
    h1 {
      font-size: 2.5rem;
    }
    
    h2 {
      font-size: 2rem;
    }
    
    h3 {
      font-size: 1.75rem;
    }
    
    .section {
      padding: var(--spacing-lg) 0;
    }
  }
`;

export default GlobalStyles; 