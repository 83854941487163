import React from 'react';
import styled from 'styled-components';
import Container from './Container';

const StyledSection = styled.section`
  padding: ${props => props.noPadding ? '0' : 'var(--spacing-xl) 0'};
  background-color: ${props => props.bgColor || 'transparent'};
  position: relative;
  overflow: hidden;
`;

const SectionHeader = styled.div`
  text-align: center;
  max-width: 800px;
  margin: 0 auto var(--spacing-xl);
`;

const SectionTitle = styled.h2`
  margin-bottom: var(--spacing-sm);
  color: ${props => props.light ? 'var(--light)' : 'var(--dark)'};
`;

const SectionSubtitle = styled.p`
  font-size: 1.25rem;
  color: ${props => props.light ? 'rgba(255, 255, 255, 0.8)' : 'var(--gray)'};
  margin-bottom: 0;
`;

const Section = ({ 
  children, 
  id, 
  title, 
  subtitle, 
  bgColor, 
  light = false,
  noPadding = false,
  small = false,
  className,
  ...props 
}) => {
  return (
    <StyledSection 
      id={id} 
      bgColor={bgColor} 
      noPadding={noPadding}
      className={className}
      {...props}
    >
      <Container small={small}>
        {(title || subtitle) && (
          <SectionHeader>
            {title && <SectionTitle light={light}>{title}</SectionTitle>}
            {subtitle && <SectionSubtitle light={light}>{subtitle}</SectionSubtitle>}
          </SectionHeader>
        )}
        {children}
      </Container>
    </StyledSection>
  );
};

export default Section; 