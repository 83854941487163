import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Section from './Section';
import Button from './Button';

const PortfolioGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-md);
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }
`;

const PortfolioItem = styled.div`
  position: relative;
  border-radius: var(--radius-md);
  overflow: hidden;
  box-shadow: var(--shadow-md);
  height: 300px;
  
  &:hover {
    .overlay {
      opacity: 1;
    }
  }
`;

const PortfolioImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform var(--transition-normal);
  
  ${PortfolioItem}:hover & {
    transform: scale(1.05);
  }
`;

const PortfolioOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(249, 115, 22, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity var(--transition-normal);
  padding: var(--spacing-md);
  text-align: center;
  color: white;
`;

const PortfolioTitle = styled.h4`
  color: white;
  margin-bottom: var(--spacing-sm);
`;

const PortfolioCategory = styled.p`
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: var(--spacing-md);
`;

const ViewAllContainer = styled.div`
  text-align: center;
  margin-top: var(--spacing-xl);
`;

const PortfolioSection = () => {
  const { t } = useTranslation();

  const portfolioItems = [
    {
      id: 1,
      title: t('portfolio.items.ecommerce.title'),
      category: t('portfolio.items.ecommerce.category'),
      image: 'https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
      alt: t('portfolio.items.ecommerce.alt'),
    },
    {
      id: 2,
      title: t('portfolio.items.saas.title'),
      category: t('portfolio.items.saas.category'),
      image: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
      alt: t('portfolio.items.saas.alt'),
    },
    {
      id: 3,
      title: t('portfolio.items.mobile.title'),
      category: t('portfolio.items.mobile.category'),
      image: 'https://images.unsplash.com/photo-1522542550221-31fd19575a2d?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
      alt: t('portfolio.items.mobile.alt'),
    },
    {
      id: 4,
      title: t('portfolio.items.event.title'),
      category: t('portfolio.items.event.category'),
      image: 'https://images.unsplash.com/photo-1505373877841-8d25f7d46678?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
      alt: t('portfolio.items.event.alt'),
    },
    {
      id: 5,
      title: t('portfolio.items.realestate.title'),
      category: t('portfolio.items.realestate.category'),
      image: 'https://images.unsplash.com/photo-1560518883-ce09059eeffa?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
      alt: t('portfolio.items.realestate.alt'),
    },
    {
      id: 6,
      title: t('portfolio.items.restaurant.title'),
      category: t('portfolio.items.restaurant.category'),
      image: 'https://images.unsplash.com/photo-1555396273-367ea4eb4db5?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
      alt: t('portfolio.items.restaurant.alt'),
    },
  ];

  return (
    <Section 
      id="portfolio" 
      title={t('portfolio.title')} 
      subtitle={t('portfolio.subtitle')}
    >
      <PortfolioGrid>
        {portfolioItems.map((item) => (
          <PortfolioItem key={item.id}>
            <PortfolioImage src={item.image} alt={item.alt} />
            <PortfolioOverlay className="overlay">
              <PortfolioTitle>{item.title}</PortfolioTitle>
              <PortfolioCategory>{item.category}</PortfolioCategory>
              {/* <Button 
                variant="outline" 
                size="small"
                aria-label={t('portfolio.viewProject', { title: item.title })}
              >
                {t('portfolio.viewProject')}
              </Button> */}
            </PortfolioOverlay>
          </PortfolioItem>
        ))}
      </PortfolioGrid>
      
      {/* <ViewAllContainer>
        <Button variant="ghost">{t('portfolio.viewAll')}</Button>
      </ViewAllContainer> */}
    </Section>
  );
};

export default PortfolioSection; 