import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Section from './Section';
import Button from './Button';

const CTAContainer = styled.div`
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: var(--spacing-xl) 0;
`;

const CTATitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: var(--spacing-md);
  color: white;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const CTASubtitle = styled.p`
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: var(--spacing-lg);
  
  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
`;

const CTABackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, var(--primary) 0%, var(--primary-dark) 100%);
  opacity: 0.95;
  z-index: -1;
`;

const CTABackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80');
  background-size: cover;
  background-position: center;
  z-index: -2;
`;

const CTASection = () => {
  const { t } = useTranslation();
  
  const scrollToSection = (id) => (e) => {
    e.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Section 
      id="cta" 
      noPadding
      light
    >
      <CTABackgroundImage />
      <CTABackground />
      <CTAContainer>
        <CTATitle>{t('cta.title')}</CTATitle>
        <CTASubtitle>{t('cta.subtitle')}</CTASubtitle>
        <Button 
          size="large"
          onClick={scrollToSection('contact')}
        >
          {t('cta.button')}
        </Button>
      </CTAContainer>
    </Section>
  );
};

export default CTASection; 