import React from 'react';
import styled, { css } from 'styled-components';

const ButtonVariants = {
  primary: css`
    background-color: var(--primary);
    color: white;
    &:hover {
      background-color: var(--primary-dark);
    }
  `,
  secondary: css`
    background-color: var(--secondary);
    color: white;
    &:hover {
      background-color: var(--secondary-dark);
    }
  `,
  outline: css`
    background-color: transparent;
    color: var(--primary);
    border: 2px solid var(--primary);
    &:hover {
      background-color: var(--primary);
      color: white;
    }
  `,
  ghost: css`
    background-color: transparent;
    color: var(--primary);
    &:hover {
      background-color: rgba(37, 99, 235, 0.1);
    }
  `,
};

const ButtonSizes = {
  small: css`
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
  `,
  medium: css`
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
  `,
  large: css`
    padding: 1rem 2rem;
    font-size: 1.125rem;
  `,
};

const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-primary);
  font-weight: 600;
  border-radius: var(--radius-md);
  border: none;
  cursor: pointer;
  transition: all var(--transition-fast);
  
  ${props => ButtonVariants[props.variant] || ButtonVariants.primary}
  ${props => ButtonSizes[props.size] || ButtonSizes.medium}
  
  width: ${props => props.fullWidth ? '100%' : 'auto'};
  opacity: ${props => props.disabled ? 0.7 : 1};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.3);
  }
  
  svg {
    margin-right: ${props => props.children ? '0.5rem' : '0'};
  }
`;

const Button = ({ 
  children, 
  variant = 'primary', 
  size = 'medium', 
  type = 'button',
  fullWidth = false,
  disabled = false,
  onClick,
  ...props 
}) => {
  return (
    <StyledButton
      type={type}
      variant={variant}
      size={size}
      fullWidth={fullWidth}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      {children}
    </StyledButton>
  );
};

export default Button; 