import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Container from './Container';

const FooterWrapper = styled.footer`
  background-color: var(--dark);
  color: var(--light);
  padding: var(--spacing-xl) 0;
`;

const FooterContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-lg);
  
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

const FooterColumn = styled.div``;

const FooterTitle = styled.h4`
  color: var(--light);
  margin-bottom: var(--spacing-md);
  font-size: 1.25rem;
`;

const FooterList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const FooterListItem = styled.li`
  margin-bottom: var(--spacing-sm);
`;

const FooterLink = styled.a`
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  transition: color var(--transition-fast);
  
  &:hover {
    color: var(--light);
  }
`;

const FooterText = styled.p`
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: var(--spacing-sm);
`;

const FooterBottom = styled.div`
  margin-top: var(--spacing-xl);
  padding-top: var(--spacing-md);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  @media (max-width: 768px) {
    flex-direction: column;
    gap: var(--spacing-md);
  }
`;

const FooterCopyright = styled.p`
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
`;

const FooterBottomLinks = styled.div`
  display: flex;
  gap: var(--spacing-md);
`;

const SocialLinks = styled.div`
  display: flex;
  gap: var(--spacing-sm);
  margin-top: var(--spacing-md);
`;

const SocialLink = styled.a`
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.25rem;
  transition: color var(--transition-fast);
  
  &:hover {
    color: var(--light);
  }
`;

const Footer = () => {
  const { t } = useTranslation();

  const socialLinks = [
    { id: 'facebook', icon: 'fab fa-facebook-f', label: 'Facebook', url: '#' },
    { id: 'twitter', icon: 'fab fa-twitter', label: 'Twitter', url: '#' },
    { id: 'instagram', icon: 'fab fa-instagram', label: 'Instagram', url: '#' },
    { id: 'linkedin', icon: 'fab fa-linkedin-in', label: 'LinkedIn', url: '#' },
  ];

  return (
    <FooterWrapper>
      <Container>
        <FooterContainer>
          <FooterColumn>
            <FooterTitle>AI.Impactix</FooterTitle>
            <FooterText>
            {t('footer.mission')}
            </FooterText>
            <SocialLinks>
              {socialLinks.map(link => (
                <SocialLink 
                  key={link.id}
                  href={link.url} 
                  aria-label={link.label}
                >
                  <i className={link.icon}></i>
                </SocialLink>
              ))}
            </SocialLinks>
          </FooterColumn>
          
          <FooterColumn>
            <FooterTitle>{t('nav.services')}</FooterTitle>
            <FooterList>
              <FooterListItem>
                <FooterLink href="#">{t('services.service1.title')}</FooterLink>
              </FooterListItem>
              <FooterListItem>
                <FooterLink href="#">{t('services.service2.title')}</FooterLink>
              </FooterListItem>
              <FooterListItem>
                <FooterLink href="#">{t('services.service3.title')}</FooterLink>
              </FooterListItem>
              <FooterListItem>
                <FooterLink href="#">{t('services.service4.title')}</FooterLink>
              </FooterListItem>
            </FooterList>
          </FooterColumn>
          
          <FooterColumn>
            <FooterTitle>{t('nav.contact')}</FooterTitle>
            <FooterText>{t('contact.address')}</FooterText>
            <FooterText>{t('contact.phone_contact')}</FooterText>
            <FooterText>{t('contact.email_contact')}</FooterText>
          </FooterColumn>
        </FooterContainer>
        
        <FooterBottom>
          <FooterCopyright>
            {t('footer.copyright')}
          </FooterCopyright>
          
          <FooterBottomLinks>
            <FooterLink href="#">{t('footer.privacy')}</FooterLink>
            <FooterLink href="#">{t('footer.terms')}</FooterLink>
          </FooterBottomLinks>
        </FooterBottom>
      </Container>
    </FooterWrapper>
  );
};

export default Footer; 