import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Container from './Container';
import LanguageSwitcher from './LanguageSwitcher';
import Button from './Button';

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: ${props => props.scrolled ? 'white' : 'transparent'};
  box-shadow: ${props => props.scrolled ? 'var(--shadow-md)' : 'none'};
  transition: all var(--transition-normal);
  padding: ${props => props.scrolled ? '0.75rem 0' : '1.5rem 0'};
`;

const HeaderContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Logo = styled.a`
  font-family: var(--font-heading);
  font-weight: 700;
  font-size: 1.5rem;
  color: ${props => props.scrolled ? 'var(--primary)' : 'var(--light)'};
  text-decoration: none;
  transition: color var(--transition-fast);
  
  &:hover {
    color: ${props => props.scrolled ? 'var(--primary-dark)' : 'var(--light)'};
  }
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: ${props => props.isOpen ? 'flex' : 'none'};
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    box-shadow: var(--shadow-md);
    padding: 1rem 0;
  }
`;

const NavList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

const NavItem = styled.li`
  margin: 0 1rem;
  
  @media (max-width: 768px) {
    margin: 0.5rem 0;
    width: 100%;
    text-align: center;
  }
`;

const NavLink = styled.a`
  color: ${props => props.scrolled ? 'var(--dark)' : 'var(--light)'};
  text-decoration: none;
  font-weight: 500;
  transition: color var(--transition-fast);
  padding: 0.5rem;
  
  &:hover {
    color: var(--primary);
  }
  
  @media (max-width: 768px) {
    color: var(--dark);
    display: block;
    padding: 0.5rem 1rem;
  }
`;

const NavActions = styled.div`
  display: flex;
  align-items: center;
  margin-left: 2rem;
  
  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 1rem;
    width: 100%;
    justify-content: center;
  }
`;

const MobileMenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  color: ${props => props.scrolled ? 'var(--dark)' : 'var(--light)'};
  font-size: 1.5rem;
  cursor: pointer;
  
  @media (max-width: 768px) {
    display: block;
  }
`;

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useTranslation();

  const scrollToSection = (id) => (e) => {
    e.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Memoize the scroll handler to prevent unnecessary re-renders
  const handleScroll = useCallback(() => {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Close mobile menu when clicking outside
  useEffect(() => {
    if (!isMenuOpen) return;

    const handleClickOutside = (event) => {
      const nav = document.querySelector('nav');
      const menuButton = document.querySelector('button[aria-label="Toggle menu"]');
      
      if (
        nav && 
        !nav.contains(event.target) && 
        menuButton && 
        !menuButton.contains(event.target)
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <HeaderWrapper scrolled={scrolled}>
      <HeaderContainer>
        <Logo href="https://aimpactix.com" scrolled={scrolled} target="_blank">AI.Impactix</Logo>
        
        <MobileMenuButton 
          onClick={toggleMenu} 
          scrolled={scrolled}
          aria-label="Toggle menu"
          aria-expanded={isMenuOpen}
        >
          {isMenuOpen ? '✕' : '☰'}
        </MobileMenuButton>
        
        <Nav isOpen={isMenuOpen}>
          <NavList>
            <NavItem>
              <NavLink href="#home" scrolled={scrolled}>{t('nav.home')}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#services" scrolled={scrolled}>{t('nav.services')}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#portfolio" scrolled={scrolled}>{t('nav.portfolio')}</NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink href="#pricing" scrolled={scrolled}>{t('nav.pricing')}</NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink href="#contact" scrolled={scrolled}>{t('nav.contact')}</NavLink>
            </NavItem>
          </NavList>
          
          <NavActions>
            <LanguageSwitcher />
            <Button 
              variant={scrolled ? 'primary' : 'outline'} 
              size="small" 
              style={{ marginLeft: '1rem' }}
              onClick={scrollToSection('contact')}
              >
              {t('hero.cta')}
            </Button>
          </NavActions>
        </Nav>
      </HeaderContainer>
    </HeaderWrapper>
  );
};

export default Header; 