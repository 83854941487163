import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Section from './Section';
import Button from './Button';

const ContactContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-xl);
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ContactForm = styled.form`
  background-color: white;
  border-radius: var(--radius-md);
  padding: var(--spacing-lg);
  box-shadow: var(--shadow-md);
`;

const FormGroup = styled.div`
  margin-bottom: var(--spacing-md);
`;

const FormLabel = styled.label`
  display: block;
  margin-bottom: var(--spacing-xs);
  font-weight: 500;
`;

const FormInput = styled.input`
  width: 100%;
  padding: var(--spacing-sm) var(--spacing-md);
  border: 1px solid var(--gray-light);
  border-radius: var(--radius-md);
  font-size: 1rem;
  transition: border-color var(--transition-fast);
  
  &:focus {
    outline: none;
    border-color: var(--primary);
  }
`;

const FormTextarea = styled.textarea`
  width: 100%;
  padding: var(--spacing-sm) var(--spacing-md);
  border: 1px solid var(--gray-light);
  border-radius: var(--radius-md);
  font-size: 1rem;
  transition: border-color var(--transition-fast);
  min-height: 150px;
  resize: vertical;
  
  &:focus {
    outline: none;
    border-color: var(--primary);
  }
`;

const ContactInfo = styled.div``;

const ContactInfoItem = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--spacing-md);
`;

const ContactInfoIcon = styled.div`
  width: 40px;
  height: 40px;
  background-color: var(--primary);
  color: white;
  border-radius: var(--radius-full);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: var(--spacing-md);
  flex-shrink: 0;
`;

const ContactInfoContent = styled.div``;

const ContactInfoTitle = styled.h4`
  margin-bottom: var(--spacing-xs);
`;

const ContactInfoText = styled.p`
  color: var(--gray);
  margin-bottom: 0;
`;

const ContactMap = styled.div`
  margin-top: var(--spacing-lg);
  border-radius: var(--radius-md);
  overflow: hidden;
  height: 300px;
  
  iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

const FormSuccess = styled.div`
  background-color: var(--success);
  color: white;
  padding: var(--spacing-md);
  border-radius: var(--radius-md);
  margin-bottom: var(--spacing-md);
`;

const FormError = styled.div`
  background-color: ${props => props.small ? 'transparent' : 'var(--danger)'};
  color: ${props => props.small ? 'var(--danger)' : 'white'};
  padding: ${props => props.small ? 'var(--spacing-xs) 0' : 'var(--spacing-md)'};
  border-radius: ${props => props.small ? '0' : 'var(--radius-md)'};
  margin-bottom: ${props => props.small ? 'var(--spacing-xs)' : 'var(--spacing-md)'};
  font-size: ${props => props.small ? '0.875rem' : '1rem'};
`;

// Snackbar component
const Snackbar = styled.div`
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 300px;
  max-width: 500px;
  padding: var(--spacing-md);
  border-radius: var(--radius-md);
  color: white;
  font-weight: 500;
  text-align: center;
  z-index: 1000;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  opacity: ${props => props.visible ? '1' : '0'};
  visibility: ${props => props.visible ? 'visible' : 'hidden'};
  transition: opacity 0.3s, visibility 0.3s;
  background-color: ${props => 
    props.type === 'success' ? 'var(--success)' : 
    props.type === 'error' ? 'var(--danger)' : 
    'var(--primary)'
  };
`;

const ContactSection = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const [formStatus, setFormStatus] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [snackbar, setSnackbar] = useState({
    visible: false,
    type: '',
    message: ''
  });

  // Xử lý hiển thị và ẩn snackbar
  useEffect(() => {
    let timer;
    if (snackbar.visible) {
      timer = setTimeout(() => {
        setSnackbar(prev => ({ ...prev, visible: false }));
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [snackbar.visible]);

  const showSnackbar = (type, message) => {
    setSnackbar({
      visible: true,
      type,
      message
    });
  };

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.name.trim()) {
      newErrors.name = t('contact.errors.name');
    }
    
    if (!formData.email.trim()) {
      newErrors.email = t('contact.errors.email_required');
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = t('contact.errors.email_invalid');
    }
    
    if (!formData.message.trim()) {
      newErrors.message = t('contact.errors.message');
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    
    // Clear error when user types
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: null
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }
    
    setIsSubmitting(true);
    setFormStatus(null);
    
    try {
      // Gửi dữ liệu đến API Telegram
      const apiUrl = '/api/send-telegram';
        
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      
      const data = await response.json();
      
      if (data.success) {
        setFormStatus('success');
        showSnackbar('success', t('contact.success_message'));
        setFormData({
          name: '',
          email: '',
          phone: '',
          message: '',
        });
      } else {
        console.error('Error sending message:', data.error);
        setFormStatus('error');
        showSnackbar('error', t('contact.error_message'));
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setFormStatus('error');
      showSnackbar('error', t('contact.error_message'));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Section 
      id="contact" 
      title={t('contact.title')} 
      subtitle={t('contact.subtitle')}
      bgColor="var(--gray-light)"
    >
      {/* Snackbar */}
      <Snackbar 
        visible={snackbar.visible} 
        type={snackbar.type}
      >
        {snackbar.message}
      </Snackbar>
      
      <ContactContainer>
        <ContactForm onSubmit={handleSubmit}>
          <FormGroup>
            <FormLabel htmlFor="name">{t('contact.name')} *</FormLabel>
            <FormInput 
              type="text" 
              id="name" 
              name="name" 
              value={formData.name}
              onChange={handleChange}
              required 
              aria-invalid={errors.name ? "true" : "false"}
            />
            {errors.name && <FormError small>{errors.name}</FormError>}
          </FormGroup>
          
          <FormGroup>
            <FormLabel htmlFor="email">{t('contact.email')} *</FormLabel>
            <FormInput 
              type="email" 
              id="email" 
              name="email" 
              value={formData.email}
              onChange={handleChange}
              required 
              aria-invalid={errors.email ? "true" : "false"}
            />
            {errors.email && <FormError small>{errors.email}</FormError>}
          </FormGroup>
          
          <FormGroup>
            <FormLabel htmlFor="phone">{t('contact.phone')}</FormLabel>
            <FormInput 
              type="tel" 
              id="phone" 
              name="phone" 
              value={formData.phone}
              onChange={handleChange}
            />
          </FormGroup>
          
          <FormGroup>
            <FormLabel htmlFor="message">{t('contact.message')} *</FormLabel>
            <FormTextarea 
              id="message" 
              name="message" 
              value={formData.message}
              onChange={handleChange}
              required 
              aria-invalid={errors.message ? "true" : "false"}
            />
            {errors.message && <FormError small>{errors.message}</FormError>}
          </FormGroup>
          
          <Button 
            type="submit" 
            fullWidth
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Đang gửi...' : t('contact.submit')}
          </Button>
        </ContactForm>
        
        <ContactInfo>
          <ContactInfoItem>
            <ContactInfoIcon>
              <i className="fas fa-map-marker-alt"></i>
            </ContactInfoIcon>
            <ContactInfoContent>
              <ContactInfoTitle>Address</ContactInfoTitle>
              <ContactInfoText>{t('contact.address')}</ContactInfoText>
            </ContactInfoContent>
          </ContactInfoItem>
          
          <ContactInfoItem>
            <ContactInfoIcon>
              <i className="fas fa-phone-alt"></i>
            </ContactInfoIcon>
            <ContactInfoContent>
              <ContactInfoTitle>Phone</ContactInfoTitle>
              <ContactInfoText>{t('contact.phone_contact')}</ContactInfoText>
            </ContactInfoContent>
          </ContactInfoItem>
          
          <ContactInfoItem>
            <ContactInfoIcon>
              <i className="fas fa-envelope"></i>
            </ContactInfoIcon>
            <ContactInfoContent>
              <ContactInfoTitle>Email</ContactInfoTitle>
              <ContactInfoText>{t('contact.email_contact')}</ContactInfoText>
            </ContactInfoContent>
          </ContactInfoItem>
          
          <ContactMap>
            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.725988888889!2d106.70088888888888!3d10.739999999999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f7a7a7a7a7a%3A0x7a7a7a7a7a7a7a7a!2zNjAgTMawbSBWxINuIEJlu4NuLCBUw6JuIEtp4buDbSwgUXVhbiA3LCBUaMOgbmggcGjhu5EgSOG7kyBDaMOtIE1pbmgsIFZp4buHdCBOYW0!5e0!3m2!1svi!2svnn!4v1708982400000" 
              allowFullScreen="" 
              loading="lazy"
              title="Office Location"
            ></iframe>
          </ContactMap>
        </ContactInfo>
      </ContactContainer>
    </Section>
  );
};

export default ContactSection; 