import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Section from './Section';

const ProcessContainer = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 4px;
    background-color: var(--primary);
    transform: translateX(-50%);
    
    @media (max-width: 768px) {
      left: 20px;
      transform: none;
    }
  }
`;

const ProcessStep = styled.div`
  display: flex;
  margin-bottom: var(--spacing-xl);
  position: relative;
  
  &:last-child {
    margin-bottom: 0;
  }
  
  &:nth-child(odd) {
    flex-direction: row-reverse;
    
    @media (max-width: 768px) {
      flex-direction: row;
    }
  }
`;

const ProcessContent = styled.div`
  width: 50%;
  padding: 0 var(--spacing-lg);
  
  @media (max-width: 768px) {
    width: calc(100% - 60px);
    padding: 0 0 0 var(--spacing-lg);
  }
`;

const ProcessNumber = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 40px;
  background-color: var(--primary);
  color: white;
  border-radius: var(--radius-full);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  z-index: 1;
  
  @media (max-width: 768px) {
    left: 20px;
    transform: none;
  }
`;

const ProcessTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: var(--spacing-sm);
`;

const ProcessDescription = styled.p`
  color: var(--gray);
  margin-bottom: 0;
`;

const ProcessSection = () => {
  const { t } = useTranslation();

  const steps = [
    {
      id: 'step1',
      number: '1',
      title: t('process.step1.title'),
      description: t('process.step1.description'),
    },
    {
      id: 'step2',
      number: '2',
      title: t('process.step2.title'),
      description: t('process.step2.description'),
    },
    {
      id: 'step3',
      number: '3',
      title: t('process.step3.title'),
      description: t('process.step3.description'),
    },
    {
      id: 'step4',
      number: '4',
      title: t('process.step4.title'),
      description: t('process.step4.description'),
    },
  ];

  return (
    <Section 
      id="process" 
      title={t('process.title')}
      bgColor="var(--gray-light)"
    >
      <ProcessContainer>
        {steps.map((step) => (
          <ProcessStep key={step.id}>
            <ProcessNumber>{step.number}</ProcessNumber>
            <ProcessContent>
              <ProcessTitle>{step.title}</ProcessTitle>
              <ProcessDescription>{step.description}</ProcessDescription>
            </ProcessContent>
          </ProcessStep>
        ))}
      </ProcessContainer>
    </Section>
  );
};

export default ProcessSection; 