import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Container from './Container';
import Button from './Button';

const HeroWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: linear-gradient(135deg, var(--primary) 0%, var(--primary-dark) 100%);
  color: var(--light);
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80');
    background-size: cover;
    background-position: center;
    opacity: 0.1;
    z-index: 0;
  }
`;

const HeroContent = styled(Container)`
  position: relative;
  z-index: 2;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const HeroTitle = styled.h1`
  font-size: 3.4rem;
  font-weight: 800;
  margin-bottom: 1.5rem;
  line-height: 1.5;
  
  @media (max-width: 768px) {
    font-size: 3rem;
  }
  
  @media (max-width: 480px) {
    font-size: 2.5rem;
  }
`;

const HeroSubtitle = styled.p`
  font-size: 1.5rem;
  max-width: 800px;
  margin: 0 auto 3rem;
  
  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

const HeroButtons = styled.div`
  display: flex;
  gap: 1rem;
  
  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

const ButtonLink = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-primary);
  font-weight: 600;
  border-radius: var(--radius-md);
  border: none;
  cursor: pointer;
  transition: all var(--transition-fast);
  text-decoration: none;
  
  &.primary {
    background-color: var(--primary);
    color: white;
    padding: 1rem 2rem;
    font-size: 1.125rem;
    
    &:hover {
      background-color: var(--primary-dark);
    }
  }
  
  &.outline {
    background-color: transparent;
    color: var(--primary);
    border: 2px solid var(--primary);
    padding: 1rem 2rem;
    font-size: 1.125rem;
    
    &:hover {
      background-color: var(--primary);
      color: white;
    }
  }
`;

const ScrollDown = styled.a`
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  color: var(--light);
  font-size: 2rem;
  animation: bounce 2s infinite;
  z-index: 2;
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0) translateX(-50%);
    }
    40% {
      transform: translateY(-20px) translateX(-50%);
    }
    60% {
      transform: translateY(-10px) translateX(-50%);
    }
  }
`;

const HeroSection = () => {
  const { t } = useTranslation();

  const scrollToSection = (id) => (e) => {
    e.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <HeroWrapper id="home">
      <HeroContent>
        <HeroTitle>{t('hero.title')}</HeroTitle>
        <HeroSubtitle>{t('hero.subtitle')}</HeroSubtitle>
        <HeroButtons>
          <ButtonLink 
            href="#contact" 
            className="primary"
            onClick={scrollToSection('contact')}
          >
            {t('hero.cta')}
          </ButtonLink>
          <ButtonLink 
            href="#portfolio" 
            className="outline"
            onClick={scrollToSection('portfolio')}
          >
            {t('hero.portfolio')}
          </ButtonLink>
        </HeroButtons>
      </HeroContent>
      <ScrollDown 
        href="#services" 
        onClick={scrollToSection('services')}
        aria-label="Scroll down to services section"
      >
        <i className="fas fa-chevron-down"></i>
      </ScrollDown>
    </HeroWrapper>
  );
};

export default HeroSection; 